export enum ENVIRONMENT_TYPE {
  LOCAL = 'LOCAL',
  LOCAL_LOCAL = 'LOCAL_LOCAL',
  TEST = 'TEST',
  STAGING = 'STAGING',
  PROD = 'PROD',
}

interface IEnvironment {
  name: string;
  domain?: string;
  apiUrl: string;

  auth0Domain: string,
  auth0CustomDomain: string,
  auth0ClientId: string,

  tagColor: string;
  isForDebugging: boolean; //if TRUE, enables more logs, testing features etc for this environment. typically only on for LOCAL and TEST
  //shortcutAccessCode?: string;
  //hotjarId?: number;

  mixPanelEnv: string;
}

export const ENVIRONMENT_LOOKUP: { [key in ENVIRONMENT_TYPE]: IEnvironment } = {
  [ENVIRONMENT_TYPE.LOCAL_LOCAL]: { // local using a local deployment
    name: 'Local \n Docker DB',
    
    apiUrl: 'http://localhost:3030',

    auth0Domain: 'aheadofthegame-test.us.auth0.com', //same as TEST
    auth0CustomDomain: 'auth.aheadofthegame.test.movember.com',
    auth0ClientId: 'GylrzH6TSNJ9oNLFouQhA6UvpFmoCVpo', //same as TEST

    tagColor: '#ff4346',
    isForDebugging: true,

    mixPanelEnv: 'local',
  },
  [ENVIRONMENT_TYPE.LOCAL]: { // local using the test DB
    name: 'Local \n AWS-Test DB',
    // 'domain' could be aotgadmin.dev.movember.com etc. This one isn't important.
    apiUrl: 'https://api.aotg.test.movember.com',
    auth0Domain: 'aheadofthegame-test.us.auth0.com', //same as TEST
    auth0CustomDomain: 'auth.aheadofthegame.test.movember.com',
    auth0ClientId: 'GylrzH6TSNJ9oNLFouQhA6UvpFmoCVpo', //same as TEST

    tagColor: '#ff8900',
    isForDebugging: true,

    mixPanelEnv: 'local',
  },
  [ENVIRONMENT_TYPE.TEST]: {
    name: 'Test',
    domain: 'https://aotgadmin.test.movember.com',
    apiUrl: 'https://api.aotg.test.movember.com',

    auth0Domain: 'aheadofthegame-test.us.auth0.com',
    auth0CustomDomain: 'auth.aheadofthegame.test.movember.com',
    auth0ClientId: 'GylrzH6TSNJ9oNLFouQhA6UvpFmoCVpo',
    
    tagColor: '#fffb00',
    isForDebugging: true,

    mixPanelEnv: 'uat',
  },
  [ENVIRONMENT_TYPE.STAGING]: {
    name: 'Staging',
    domain: 'https://aotgadmin.staging.movember.com',
    apiUrl: 'https://api.aotg.staging.movember.com',

    auth0Domain: 'aheadofthegame-staging.us.auth0.com',
    auth0CustomDomain: 'auth.aheadofthegame.staging.movember.com',
    auth0ClientId: 'psTeKlu5NcUeecnsVwsjloCVKjbhcEr5',
    
    tagColor: '#6dd911',
    isForDebugging: false,

    mixPanelEnv: 'uat',
  },
  [ENVIRONMENT_TYPE.PROD]: {
    name: 'Production',
    domain: 'https://aotgadmin.movember.com',
    apiUrl: 'https://api.aotg.movember.com',
    
    auth0Domain: 'aheadofthegame.us.auth0.com',
    auth0CustomDomain: 'auth.aheadofthegame.movember.com',
    auth0ClientId: 'eNBdOPM9G75ZlafzRB0ZFZ0N114DdIPF',

    tagColor: '', //no displayed tag for prod
    isForDebugging: false,

    mixPanelEnv: 'prod',
  }
}

export default {
  isEnvironment(type: ENVIRONMENT_TYPE): boolean {
    switch (type) {
      case ENVIRONMENT_TYPE.PROD:
        return window.location.origin === ENVIRONMENT_LOOKUP.PROD.domain;
      case ENVIRONMENT_TYPE.STAGING:
        return window.location.origin === ENVIRONMENT_LOOKUP.STAGING.domain;
      case ENVIRONMENT_TYPE.TEST:
        return window.location.origin === ENVIRONMENT_LOOKUP.TEST.domain;
      case ENVIRONMENT_TYPE.LOCAL_LOCAL:
        // check we're on localhost, but also check if we have a flag flipped in localstorage
        return (window.location.origin.startsWith('http://localhost') 
          || window.location.origin.startsWith('https://localhost')
          || window.location.origin.includes('.dev.')) && localStorage.getItem('useLocalServer') === 'true';
      case ENVIRONMENT_TYPE.LOCAL:
        return (window.location.origin.startsWith('http://localhost') 
          || window.location.origin.startsWith('https://localhost')
          || window.location.origin.includes('.dev.')) && localStorage.getItem('useLocalServer') !== 'true';
      default:
        throw new Error('Invalid environment type');
    }
  },

  getCurrentEnvironment(): IEnvironment {
    const currentEnvType = Object.keys(ENVIRONMENT_LOOKUP).find((type) => this.isEnvironment(type as ENVIRONMENT_TYPE));
    if (!currentEnvType) throw Error('Missing environment type');
    return ENVIRONMENT_LOOKUP[currentEnvType as ENVIRONMENT_TYPE];
  },
  
  getEnvironmentByType(type: ENVIRONMENT_TYPE): IEnvironment {
    return ENVIRONMENT_LOOKUP[type];
  }
}