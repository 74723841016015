/**
 * This page is using code given from the Mixpanel team and edited to work wiht our code base
 * Changes made to the code:
 *  - moved initialisation to a init functionpushEvent
 *  - export the Mixpanel object
 *  - using our getCurrentEnv to send to correct mixpanel tracking endpoint (aka uat vs prod)
 */ 

import Environment, { ENVIRONMENT_TYPE } from './Environment';

const MixPanel = {
  init() {
    document.addEventListener('DOMContentLoaded', function () {
      window.dataLayer.push({
        'event': 'page_view',
        'current_uri': window.location.href,
        'last_uri': document.referrer,
        'page_title': document.title,
      });
    });

    // Set up dataLayer.push interception
    let originalPush = window.dataLayer.push;
    window.dataLayer.push = function () {
      let result = originalPush.apply(this, arguments);
      handleDataLayerEvent(arguments[0]);
      return result;
    };
    // Process any existing events in the data layer
    for (let i = 0; i < window.dataLayer.length; i++) {
      handleDataLayerEvent(window.dataLayer[i]);
    }
    // Set up history change listeners for SPAs
    if (window.history && window.history.pushState) {
      let originalPushState = window.history.pushState;
      window.history.pushState = function () {
        originalPushState.apply(this, arguments);
        handleDataLayerEvent({ event: 'gtm.js' }); // Simulate a page view event
      };
      window.addEventListener('popstate', function () {
        handleDataLayerEvent({ event: 'gtm.js' }); // Simulate a page view event
      });
    }
    // Set up hash change listener for SPAs that use hash-based routing
    window.addEventListener('hashchange', function () {
      handleDataLayerEvent({ event: 'gtm.js' }); // Simulate a page view event
    });
    // Initial page load handling (in case gtm.js hasn't fired yet)
    handleDataLayerEvent({ event: 'gtm.js' });
  }
}

const allowedEvents = (eventName) => {
  const eventMappings = {
    submit_form: 'form_submitted',
    page_view: 'page_viewed',
    begin_checkout: 'checkout_started',
    add_payment_info: 'payment_info_added',
    add_shipping_info: 'shipping_info_added',
    purchase: 'purchase_completed',
    progress_checkout: 'checkout_progressed',
    chapter_complete: 'chapter_completed',
    chapter_start: 'chapter_started',
    click_reaction: 'reaction_clicked',
    conversation_answer: 'conversation_answered',
    conversation_complete: 'conversation_completed',
    conversation_confidence: 'confidence_selected',
    conversation_feedback: 'feedback_displayed',
    conversation_view: 'conversation_viewed',
    conversation_start: 'conversation_started',
    episode_answer: 'episode_answered',
    episode_complete: 'episode_completed',
    episode_feedback: 'episode_feedback_displayed',
    episode_resume: 'episode_resumed',
    episode_start: 'episode_started',
    episode_view: 'episode_viewed',
    quiz_answer: 'quiz_answered',
    quiz_back: 'quiz_back_clicked',
    quiz_complete: 'quiz_completed',
    quiz_feedback: 'quiz_feedback_displayed',
    quiz_next: 'quiz_next_clicked',
    quiz_start: 'quiz_started',
    click: 'link_clicked',
    click_accordion: 'accordion_clicked',
    click_carousel: 'carousel_clicked',
    click_cta: 'cta_clicked',
    click_email: 'email_link_clicked',
    click_filter: 'filter_applied',
    click_footer_nav: 'footer_nav_clicked',
    click_header_nav: 'header_nav_clicked',
    click_menu_nav: 'menu_nav_clicked',
    click_phone: 'phone_link_clicked',
    click_search_result: 'search_result_clicked',
    click_sidebar_nav: 'sidebar_nav_clicked',
    click_tab: 'tab_clicked',
    click_tile: 'tile_clicked',
    close_chat: 'chat_closed',
    close_popup: 'popup_closed',
    dwell_on_content: 'content_dwelled',
    file_download: 'file_downloaded',
    search: 'search_performed',
    share: 'content_shared',
    start_chat: 'chat_started',
    view_errors: 'errors_viewed',
    view_search_result: 'search_results_viewed',
    complete_form: 'form_completed',
    enter_field_form: 'form_field_entered',
    progress_form: 'form_progressed',
    start_form: 'form_started',
    login: 'user_logged_in',
    logout: 'user_logged_out',
    meta_data: 'meta_data_loaded',
    video_complete: 'video_completed',
    video_pause: 'video_paused',
    video_progress: 'video_progressed',
    video_resume: 'video_resumed',
    video_seek: 'video_seeked',
    video_start: 'video_started'
  };
  return eventMappings[eventName] || eventName;
}
// List of UTM and marketing parameters to track
let trackingParams = [
  'utm_source',
  'utm_medium',
  'utm_campaign',
  'utm_term',
  'utm_content',
  'fbclid',
  'gclid',
  'msclkid',
  'dclid',
  'twclid',
  'li_fat_id'
];
const getFingerprint = () => {
  let ua = navigator.userAgent;
  let browserInfo = getBrowserInfo(ua);
  let osInfo = getOSInfo(ua);
  let deviceType = getDeviceType(ua);
  let referrerInfo = getReferrerInfo();
  return {
    "user_agent": ua,
    "\$browser": browserInfo.name,
    "\$browser_version": browserInfo.version,
    "\$os": osInfo.name,
    "\$os_version": osInfo.version,
    "device_type": deviceType,
    "\$screen_width": window.innerWidth,
    "\$screen_height": window.innerHeight,
    "platform": navigator.platform,
    "timezone": Intl.DateTimeFormat().resolvedOptions().timeZone,
    "screenResolution": window.innerWidth + 'x' + window.innerHeight,
    "deviceMemory": navigator.deviceMemory,
    "hardwareConcurrency": navigator.hardwareConcurrency,
    "doNotTrack": navigator.doNotTrack,
    // eslint-disable-next-line
    "colorDepth": screen.colorDepth,
    "timezoneOffset": new Date().getTimezoneOffset(),
    "language": navigator.language,
    "\$initial_referrer": referrerInfo.initial_referrer,
    "\$initial_referring_domain": referrerInfo.initial_referring_domain,
    "\$referring_domain": referrerInfo.referring_domain,
    "\$search_engine": referrerInfo.search_engine,
    "mp_keyword": referrerInfo.mp_keyword
  };
}
const getOSInfo = (ua) => {
  let osRegexes = [
    { name: 'Android', regex: /Android ([\d\.]+)/ },
    { name: 'iOS', regex: /OS ([\d_]+) like Mac OS X/ },
    { name: 'Windows', regex: /Windows NT ([\d\.]+)/ },
    { name: 'macOS', regex: /Mac OS X ([\d_]+)/ },
    { name: 'Linux', regex: /Linux/ }
  ];
  for (let i = 0; i < osRegexes.length; i++) {
    let match = ua.match(osRegexes[i].regex);
    if (match) {
      return { name: osRegexes[i].name, version: match[1] ? match[1].replace(/_/g, '.') : '' };
    }
  }
  return { name: 'Unknown', version: '' };
}
const getDeviceType = (ua) => {
  if (/mobile/i.test(ua)) return 'Mobile';
  if (/tablet/i.test(ua)) return 'Tablet';
  return 'Desktop';
}
const getReferrerInfo = () => {
  let currentReferrer = document.referrer;
  let initialReferrer = sessionStorage.getItem('initial_referrer') || currentReferrer;
  let initialReferringDomain = sessionStorage.getItem('initial_referring_domain') || extractDomain(initialReferrer);
  if (!sessionStorage.getItem('initial_referrer')) {
    sessionStorage.setItem('initial_referrer', initialReferrer);
    sessionStorage.setItem('initial_referring_domain', initialReferringDomain);
  }
  let searchEngines = {
    'google.com': 'q',
    'bing.com': 'q',
    'yahoo.com': 'p',
    'duckduckgo.com': 'q'
    // Add more search engines as needed
  };
  let referringDomain = extractDomain(currentReferrer);
  let searchEngine = '';
  let mpKeyword = '';
  for (let domain in searchEngines) {
    if (referringDomain.indexOf(domain) !== -1) {
      searchEngine = domain;
      let searchParams = new URLSearchParams(new URL(currentReferrer).search);
      mpKeyword = searchParams.get(searchEngines[domain]) || '';
      break;
    }
  }
  return {
    initial_referrer: initialReferrer,
    initial_referring_domain: initialReferringDomain,
    referring_domain: referringDomain,
    search_engine: searchEngine,
    mp_keyword: mpKeyword
  };
}
const extractDomain = (url) => {
  if (!url) return '';
  let domain = url.split('/')[2];
  return domain ? domain.replace('www.', '') : '';
}
const getBrowserInfo = (ua) => {
  let browserRegexes = [
    { name: 'Edge', regex: /Edg\/([\d\.]+)/ },
    { name: 'Opera', regex: /OPR\/([\d\.]+)/ },
    { name: 'Chrome', regex: /Chrome\/([\d\.]+)/ },
    { name: 'Firefox', regex: /Firefox\/([\d\.]+)/ },
    { name: 'Safari', regex: /Version\/([\d\.]+).*Safari/ },
    { name: 'Internet Explorer', regex: /(?:MSIE |rv:)([\d\.]+)/ }
  ];
  for (let i = 0; i < browserRegexes.length; i++) {
    let match = ua.match(browserRegexes[i].regex);
    if (match) {
      return { name: browserRegexes[i].name, version: match[1] };
    }
  }
  return { name: 'Unknown', version: '' };
}
const getEnvironment = () => {
  const env = Environment.getCurrentEnvironment();
  return env.mixPanelEnv;
}
const sendToMovemberAPI = (payload) => {
  const version = 'v22';
  // console.log(window.location.hostname)
  let env = getEnvironment() === 'prod' ? '' : 'uat.';
  const requestUrl = "https://api." + env + "movember.com/" + version + "/tracking";

  if (Environment.isEnvironment(ENVIRONMENT_TYPE.LOCAL)) {
    console.log(`LOCAL ENVIRONMENT: Mix panel event created, but *not sent*`);
    console.log(`LOCAL ENVIRONMENT: ${JSON.stringify(payload)}`);
    return;
  }

  const xhr = new XMLHttpRequest();
  xhr.open('POST', requestUrl, true);
  xhr.withCredentials = true;
  xhr.setRequestHeader('Content-Type', 'application/json');
  xhr.onload = function () {
    if (xhr.status >= 200 && xhr.status < 300) {
      // console.log('Successfully sent to API. Response:', xhr.responseText);
    } else {
      console.error('Failed to send to API. Status:', xhr.status, 'Response:', xhr.responseText);
    }
  };
  xhr.onerror = function () {
    console.error('Network error occurred while sending to API');
  };
  xhr.ontimeout = function () {
    console.error('Request timed out while sending to API');
  };
  try {
    xhr.send(JSON.stringify(payload));
  } catch (error) {
    console.error('Error sending request:', error);
  }
}
const getTrackingParams = () => {
  let params = {};
  let storedParams = JSON.parse(sessionStorage.getItem('trackingParams') || '{}');
  let urlParams = new URLSearchParams(window.location.search);
  let hasNewParams = false;
  for (let i = 0; i < trackingParams.length; i++) {
    let param = trackingParams[i];
    if (urlParams.has(param)) {
      params[param] = urlParams.get(param);
      hasNewParams = true;
    }
  }
  if (hasNewParams) {
    sessionStorage.setItem('trackingParams', JSON.stringify(params));
    return params;
  } else if (Object.keys(storedParams).length && document.referrer) {
    return storedParams;
  }
  return {};
}
// Function to get the current state of the data layer
const getDataLayerState = () => {
  let state = {};
  for (let i = 0; i < window.dataLayer.length; i++) {
    let obj = window.dataLayer[i];
    for (let prop in obj) {
      if (obj.hasOwnProperty(prop)) {
        state[prop] = obj[prop];
      }
    }
  }
  return state;
}

// Generate Random ID for session ID
const generateRandomId = () => {
  // Get current timestamp in milliseconds (base36)
  const timestamp = Date.now().toString(36);

  // Generate 3 different random strings
  const randomStr1 = Math.random().toString(36).substring(2, 15);
  const randomStr2 = Math.random().toString(36).substring(2, 15);

  // Use crypto API for additional randomness if available
  let cryptoStr = '';
  if (window.crypto && window.crypto.getRandomValues) {
    const array = new Uint32Array(2);
    window.crypto.getRandomValues(array);
    cryptoStr = array[0].toString(36) + array[1].toString(36);
  }

  // Combine all sources of randomness
  return timestamp + '-' + randomStr1 + '-' + randomStr2 + '-' + cryptoStr;
}

const getEventUuidLocalStorage = () => {
  const storageKey = '_mtsid';
  let sessionId = localStorage.getItem(storageKey);
  if (!sessionId) {
    sessionId = generateRandomId();
    localStorage.setItem(storageKey, sessionId);
  }
  return sessionId;
};

const handleDataLayerEvent = (event) => {
  if (event) {
    let eventName = event.event || event[0];
    let eventData = event.event ? event : event[1];

    const allowedTrackingEventName = allowedEvents(eventName);
    const mtsId = getEventUuidLocalStorage();

    if (allowedTrackingEventName !== eventName) {
      let fingerprint = getFingerprint();
      let trackingParams = getTrackingParams();
      let payload = {
        event: allowedTrackingEventName,
        properties: Object.assign({}, fingerprint, trackingParams, {
          page_title: document.title,
          "\$current_url": window.location.href,
          "\$referrer": document.referrer,
          mp_processing_time_ms: Date.now(),
          ip: "0",
          session_id: mtsId
        })
      };
      // List of properties to exclude
      let excludeProps = [
        'gtm.element',
        'gtm.uniqueEventId',
        'gtm.oldUrl',
        'gtm.newUrl',
        'gtm.start',
        'gtm.scrollThreshold',
        'gtm.scrollUnits',
        'gtm.scrollDirection',
        'gtm.visibleRatio',
        'gtm.visibleTime',
        'gtm.elementClasses',
        'gtm.elementId',
        'gtm.elementTarget',
        'gtm.elementText',
        'gtm.elementUrl',
        'gtm.triggers',
        'gtm.willOpenInNewWindow',
        'eventTimeout'
      ];
      // Add filtered properties from the event data
      for (let prop in eventData) {
        if (eventData.hasOwnProperty(prop) &&
          prop !== 'event' &&
          excludeProps.indexOf(prop) === -1 &&
          typeof eventData[prop] !== 'function' &&
          typeof eventData[prop] !== 'object') {
          payload.properties[prop] = eventData[prop];
        }
      }

      // // Special handling for page title for Ahead of the Game Website
      // if (payload.properties.page_title && payload.properties.page_title === '[lang]') {
      //   payload.properties.page_title = 'Home';
      // }

      // if (payload.properties.link_url) payload.properties.link_url = payload.properties.current_uri + payload.properties.link_url;
      // payload.properties.current_uri = window.location.href;

      // Get the current state of the data layer
      let dataLayerState = getDataLayerState();
      // Check for user data in the data layer state
      if (dataLayerState.user && dataLayerState.user.memberId) {
        payload.properties.memberId = dataLayerState.user.memberId;
      }
      // Special handling for user data
      if (dataLayerState.user) {
        for (let userProp in dataLayerState.user) {
          if (dataLayerState.user.hasOwnProperty(userProp) && userProp !== 'visitorIP') {
            payload.properties['user_' + userProp] = dataLayerState.user[userProp];
          }
        }
      }
      // Special handling for ecommerce data
      if (eventData.ecommerce) {
        for (let ecommerceProp in eventData.ecommerce) {
          if (eventData.ecommerce.hasOwnProperty(ecommerceProp)) {
            payload.properties['ecommerce_' + ecommerceProp] = eventData.ecommerce[ecommerceProp];
          }
        }
      }
      // Special handling for link clicks
      if (eventName === 'gtm.linkClick') {
        payload.properties.link_url = eventData['gtm.elementUrl'] || '';
        payload.properties.link_id = eventData['gtm.elementId'] || '';
        payload.properties.link_classes = eventData['gtm.elementClasses'] || '';
        payload.properties.link_target = eventData['gtm.elementTarget'] || '';
        payload.properties.link_text = eventData['gtm.elementText'] || '';
      }
      sendToMovemberAPI(payload);
    } else {
      //console.log('Event not allowed:', eventName);
    }
  }
}


export default MixPanel;