import React, { useEffect, useState } from 'react';
import { Switch, Route, Redirect, Link } from 'react-router-dom';
import { GlobalStyle, StyledEnvironmentIndicator, StyledApp, StyledAppHeader, StyledAppFooter, StyledAppBody, StyledTestingTools, StyledTestingToolsButtons } from './App.styled';
import { IS_FACILITATOR_REVIEW_DISABLED, LOCAL_STORAGE, USER_ROLE } from './constants';
import { ResetPasswordForm } from './components/Forms/ResetPasswordForm/ResetPasswordForm';
import { LoginForm } from './components/Forms/LoginForm/LoginForm';
import Auth from './services/Auth';
import UserData from './services/UserData';
import { useHistory, useLocation } from 'react-router';
import { LoadingSpinner } from './components/LoadingSpinner/LoadingSpinner';

import { PartnerView } from './components/View/PartnerView/PartnerView';
import { DeliveryPartnerView } from './components/View/DeliveryPartnerView/DeliveryPartnerView';
import { CohortEditForm } from './components/Forms/CohortEditForm/CohortEditForm';
import { checkPermission, copyToClipboard } from './utils/helper';
import { ReactComponent as Logo } from '../src/images/logo-small.svg'
import { ThemeProvider } from 'styled-components';
import { AotgAdminTheme } from './theme/AotgAdminTheme';

import Api from './services/Api';
import { AdminView } from './components/View/AdminView/AdminView';
import { FacilitatorView } from './components/View/FacilitatorView/FacilitatorView';
import { CohortView } from './components/View/CohortView/CohortView';
import { SessionView } from './components/View/SessionView/SessionView';
import { SessionEditForm } from './components/Forms/SessionEditForm/SessionEditForm';
import { DeliveryPartnerEditForm } from './components/Forms/DeliveryPartnerEditForm/DeliveryPartnerEditForm';
import { FacilitatorEditForm } from './components/Forms/FacilitatorEditForm/FacilitatorEditForm';
import { PartnerEditForm } from './components/Forms/PartnerEditForm/PartnerEditForm';
import Environment, { ENVIRONMENT_TYPE } from './services/Environment';
import DataLayer from './services/DataLayer';
import MixPanel from './services/MixPanel';
import { MyTaskView } from './components/TaskManage/MyTaskView';
import { TaskDetail } from './components/TaskManage/TaskDetail';
import { TasksProvider } from './contexts/TasksContext';
import { TaskNavigation } from './components/TaskNavigation/TaskNavigation';
import { StatisticsOverview } from './components/StatisticsOverview/StatisticsOverview';
import { HotjarInit } from './services/Hotjar';

//Trigger release comment

interface IRoute {
  path?: string,
  render: ({ match }: { match: any }) => any
};


export const App: React.FC = (props) => {
  const environment = Environment.getCurrentEnvironment();
  const history = useHistory();

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isInitialised, setIsInitialised] = useState(false);

  const [footerClickCount, setFooterClickCount] = useState(localStorage.getItem(LOCAL_STORAGE.testingToolsEnabled) ? 10 : 0);

  const location = useLocation();

  let isOnHomeScreen = false;
  let isStatisticsEnabled = false;

  /********** Initialisation ********/

  useEffect(() => {
    const init = async () => {
      DataLayer.init();
      MixPanel.init();

      HotjarInit();
    };
    init();
    //Immediately invoked async function on first render
    (async () => {
      await Auth.handleAuthentication();
      const isAuthenticated = await Auth.isAuthenticated();

      if (isAuthenticated) {
        //Authenticated. User is allowed into the portal.
        await UserData.loadUserData();

        //Check localstorage to see if the user was trying to navigate somewhere before being forced to login
        const redirectOnLogin = localStorage.getItem(LOCAL_STORAGE.redirectOnLogin);
        if(redirectOnLogin) {
          //If they were, push them along to that destination and clear the localstorage value
          history.push(redirectOnLogin);
          localStorage.removeItem(LOCAL_STORAGE.redirectOnLogin);
        }
      } else {
        //NOT authenticated. User is pushed back to login page.

        //Store the path the user was trying to access (Eg. '/facilitator/abc-123') so we can redirect to it after login
        //This is intentionally stored every time (even '/') in order to overwrite any previous path still stored
        localStorage.setItem(LOCAL_STORAGE.redirectOnLogin, window.location.pathname);
      }

      setIsAuthenticated(isAuthenticated);
      setIsInitialised(true);
    })();
  }, []);


  // Catch route changes for analytics (block happens BEFORE)
  history.block((loc) => {
    console.log('BLOCK');
    console.log(loc);
    DataLayer.pushRouteChange(loc.pathname, window.location.pathname, loc.pathname);
  });

  // Scroll to top on route changes (listen happens AFTER)
  history.listen((loc, action) => {
    console.log('LISTEN');
    console.log(loc);
    console.log(action);

    window.scrollTo(0, 0); //scroll to top of page
  });

  /********** Route setup (based on authenticated status) ********/
  const routes: IRoute[] = [];

  const addRouterForAdmin = () => {
    if (checkPermission.isAdmin()) {
      routes.push({ path: '/admin', render: () => <AdminView /> }); //'Home' for an admin/superadmin. shows list of ALL partners
      routes.push({ path: '/edit/partner/:partnerId?', render: ({ match }) => <PartnerEditForm partnerId={match.params.partnerId} /> });
    }
  }

  const addRouterForIP = () => {
    if (checkPermission.isImplementationPartnerOrHigher()) {
      routes.push({ path: '/partner/:partnerId', render: ({ match }) => <PartnerView partnerId={match.params.partnerId} /> }); //shows partner their view, OR a PARTICULAR partner's view (ie. i'm a super admin but viewing the partner view of AUS RUGBY and what they would see (userId: fbed93nfd))
      routes.push({ path: '/partner/:partnerId/:initialTabId', render: ({ match }) => <PartnerView partnerId={match.params.partnerId} initialTabId={match.params.initialTabId} /> });
  
      routes.push({ path: '/edit/deliverypartner/:deliveryPartnerId?', render: ({ match }) => <DeliveryPartnerEditForm deliveryPartnerId={match.params.deliveryPartnerId} /> })
      if(!IS_FACILITATOR_REVIEW_DISABLED) {
        routes.push({ path: '/task', render: ({ match }) => <MyTaskView /> });
        routes.push({ path: '/task/:userId', render: ({ match }) => <TaskDetail userId={match.params.userId} /> });
      }
    }
  }

  const addRouterForDP = () => {    
    if (checkPermission.isDeliveryPartnerOrHigher()) {
      routes.push({ path: '/deliverypartner/:deliveryPartnerId', render: ({ match }) => <DeliveryPartnerView deliveryPartnerId={match.params.deliveryPartnerId} /> });
      routes.push({ path: '/deliverypartner/:deliveryPartnerId/:initialTabId', render: ({ match }) => <DeliveryPartnerView deliveryPartnerId={match.params.deliveryPartnerId} initialTabId={match.params.initialTabId} /> });
  
      routes.push({ path: '/edit/facilitator/:facilitatorId?', render: ({ match }) => <FacilitatorEditForm facilitatorId={match.params.facilitatorId} /> });
    }
  }

  const addRouterForFacilitator = () => {
    if (checkPermission.isFacilitatorOrHigher()) {
      routes.push({ path: '/edit/cohort/:cohortId?', render: ({ match }) => <CohortEditForm cohortId={match.params.cohortId} /> });
      routes.push({ path: '/cohort/:cohortId', render: ({ match }) => <CohortView cohortId={match.params.cohortId} /> }); //Shows a PARTICULAR cohort
      routes.push({ path: '/cohort/:cohortId/:initialTabId', render: ({ match }) => <CohortView cohortId={match.params.cohortId} initialTabId={match.params.initialTabId} /> }); //Shows a PARTICULAR cohort
  
      routes.push({ path: '/cohort/:cohortId/session/:sessionId', render: ({ match }) => <SessionView cohortId={match.params.cohortId} sessionId={match.params.sessionId} /> }); //Shows a PARTICULAR session
  
      routes.push({ path: '/facilitator/:facilitatorId', render: ({ match }) => <FacilitatorView facilitatorId={match.params.facilitatorId} /> }); //shows facilitator their view, oR a PARTICULAR facilitator's view (i'm an admin viewing the facilitator BOB EVANS and what they would see)
      routes.push({ path: '/facilitator/:facilitatorId/:initialTabId', render: ({ match }) => <FacilitatorView facilitatorId={match.params.facilitatorId} initialTabId={match.params.initialTabId} /> }); //shows facilitator their view, oR a PARTICULAR facilitator's view (i'm an admin viewing the facilitator BOB EVANS and what they would see)
  
      routes.push({ path: '/edit/cohort/:cohortId/session', render: ({ match }) => <SessionEditForm cohortId={match.params.cohortId} /> });
      routes.push({ path: '/edit/cohort/:cohortId/session/:sessionId', render: ({ match }) => <SessionEditForm cohortId={match.params.cohortId} sessionId={match.params.sessionId} /> });
    }
  }

  if (isInitialised) {
    if (!isAuthenticated) {
      routes.push({ path: '/login', render: () => <LoginForm /> });
      routes.push({ path: '/reset-password', render: () => <ResetPasswordForm /> });

      //Home redirects to login
      routes.push({ path: '/', render: () => <Redirect to="/login" /> })
    } else {
      const userId = UserData.getUserId();
      const userRole = UserData.getUserRole();

      const getHomePath = () => {
        switch (userRole) {
          case USER_ROLE.SuperAdmin:
          case USER_ROLE.Admin:
            return `/admin`;
          case USER_ROLE.Partner:
            return `/partner/${userId}`;
          case USER_ROLE.DeliveryPartner:
            return `/deliverypartner/${userId}`;
          case USER_ROLE.Facilitator:
            return `/facilitator/${userId}`;
          case USER_ROLE.Player:
          default:
            console.log('Invalid role');
            Auth.logout();
        }
        return "/";
      };

      isOnHomeScreen = location.pathname == getHomePath();
      isStatisticsEnabled = !Environment.isEnvironment(ENVIRONMENT_TYPE.PROD) && [USER_ROLE.Admin, USER_ROLE.SuperAdmin, USER_ROLE.Partner].includes(userRole);
      
      addRouterForAdmin();
      addRouterForIP();
      addRouterForDP();
      addRouterForFacilitator();
      routes.push({ path: '/', render: () => <Redirect to={getHomePath()} />});
    }

    //Catch all other routes and push to / (which will probably then push them on to the appropriate page)
    routes.push({ render: () => <Redirect to="/" /> })
  }

  const onFooterClicked = () => {
    setFooterClickCount(footerClickCount + 1);
  }


  const onTestPushNotificationClick = () => {
    Api.triggerPushNotifications();
  }

  const onEnvironmentTagClicked = () => {
    const isLocal = Environment.isEnvironment(ENVIRONMENT_TYPE.LOCAL);
    const isLocalLocal = Environment.isEnvironment(ENVIRONMENT_TYPE.LOCAL_LOCAL);

    if (!isLocalLocal && !isLocal) return;

    if (isLocal) {
      localStorage.setItem('useLocalServer', 'true');
    } else {
      localStorage.setItem('useLocalServer', 'false');
    }
    window.location.reload();
  }

  return <ThemeProvider theme={AotgAdminTheme}>
    {!Environment.isEnvironment(ENVIRONMENT_TYPE.PROD) && <StyledEnvironmentIndicator onClick={onEnvironmentTagClicked} style={{ backgroundColor: environment.tagColor }}>{environment.name}</StyledEnvironmentIndicator>}
    <StyledApp>
      <GlobalStyle />
      {!isInitialised
        ? <LoadingSpinner />
        : <>
            <TasksProvider>
              <StyledAppHeader >
                {isAuthenticated && <>
                {checkPermission.isImplementationPartnerOrHigher() && <TaskNavigation />}
                <div id="header">
                  <Link to='/'><Logo /></Link>
                  <div>
                    {/* eslint-disable-next-line  */}
                    <span><Link to="/">{UserData.getUserName()}</Link> | </span><a id="logout" href="#" onClick={() => Auth.logout()}>Logout</a>
                  </div>
                </div>
                </>
                }
              </StyledAppHeader>
              <StyledAppBody>
                  {isStatisticsEnabled && isOnHomeScreen && <StatisticsOverview />}
                  <Switch>
                    {routes.map((route, idx) => <Route key={idx} exact path={route.path} render={route.render} />)}
                  </Switch>
              </StyledAppBody>
              <StyledAppFooter>
                <h5 onClick={() => {
                  const newCount = footerClickCount + 1;
                  setFooterClickCount(newCount);
                  if(newCount > 9 && !localStorage.getItem(LOCAL_STORAGE.testingToolsEnabled)) {
                    localStorage.setItem(LOCAL_STORAGE.testingToolsEnabled, 'true');
                  }
                }}>© 2023 Movember. All rights reserved.</h5>
                {localStorage.getItem(LOCAL_STORAGE.testingToolsEnabled) && <>
                  <StyledTestingTools>
                    <h5>TESTING FEATURES</h5>
                    <StyledTestingToolsButtons>
                      <button onClick={() => {
                        localStorage.removeItem(LOCAL_STORAGE.testingToolsEnabled);
                        setFooterClickCount(0);
                      }}>Disable testing tools</button>
                      <button onClick={onTestPushNotificationClick}>Trigger Push Notifications</button>
                      <button onClick={(e: any) => {
                        copyToClipboard(Auth.getAccessToken());
                        e.target.innerHTML = 'Copied token!'
                        setTimeout(() => {
                          e.target.innerHTML = 'Copy Auth Token'
                        }, 2000);
                      }}>Copy Auth Token</button>
                    </StyledTestingToolsButtons>
                  </StyledTestingTools>
                </>}
              </StyledAppFooter>
            </TasksProvider>
        </>
      }
    </StyledApp>
  </ThemeProvider>
};

export default App;